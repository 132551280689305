@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.title-no-decoration {
  text-decoration: none !important;
  color: black;
}

.section {
  background-color: var.$color-neutral-background-default;
  padding-top: fn.spacing(6);
  padding-bottom: fn.spacing(6);
  max-width: 1000vh;
  overflow-x: hidden;
}

.articles-carousel-wrapper {
  padding-left: 0 !important;
}

.title-divider {
  margin-bottom: fn.spacing(6);
}

.link {
  margin-top: fn.spacing(6);
  text-align: center;
}

.card-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 315px;
}

.card-title,
.card-sub-title,
.card-category {
  margin-bottom: fn.spacing(2);
}

.image-container {
  border-radius: var.$achieve-border-radius-2;
  display: block;
  overflow: hidden;
  margin-bottom: fn.spacing(2);
}

@media screen and (min-width: var.$breakpoint-md) {
  .card-container {
    max-width: 384px;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .articles-carousel-wrapper {
    overflow: hidden;
  }

  .articles-carousel-items:first-child {
    padding-left: 0 !important;
  }

  [data-arrow='true'] .articles-carousel-items:first-child {
    padding-left: fn.spacing(13) !important;
  }

  [data-arrow='true'] .articles-carousel-items:last-child {
    padding-right: fn.spacing(13) !important;
  }

  .section {
    padding-top: fn.spacing(8);
    padding-bottom: fn.spacing(8);
    padding-right: 0;
  }

  .title-divider {
    margin-bottom: fn.spacing(8);
  }
}

/*
In this UI, the Achieve content container (1280px max-width including left and right padding) must
be "faked" by calculating the X offset that would be there if the ArticlesCarousel Section included
it (setting contain="true" or the default, see the Section component for more details).
*/
$cardLeft: var.$maximum-site-content-width-narrow - (2 * var.$medium-and-up-gutter-width);

@media screen and (min-width: var.$breakpoint-xl) {
  .articles-carousel-wrapper {
    transform: translateX((calc((100% - $cardLeft) / 2)));

    &[data-arrow='true'] {
      transform: none;
    }
  }
}
